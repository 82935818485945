/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import "./basic-healthcare-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import CollapsibleBlocksWrapper from "../../components/collapsible-block/collapsible-blocks-wrapper";
import CollapsibleBlock from "../../components/collapsible-block/collapsible-block";
import OgolnaImg from "../../assets/poradnia-ogolna.svg"
import StradomiaImg from "../../assets/stradomia-wierzchnia.svg"
import DzieciecaImg from "../../assets/PORADNIA-DZIECIECA.svg"
import FullImgBlock from "../../components/fullImg-block/fullImg-block";
import DoctorRow from "../../components/row-info/doctors-row";
import {internistsDoctors, pediatrician} from './basic-healthcare-data';



const BasicHealthcarePage: React.FC<any> = () => {

  return (
    <PageWrapper className="public-tenders-page">
      <Helmet>
        <title>{'Lekarze POZ | SPZOZ Przychodnia Syców'}</title>
      </Helmet>
      <section className="pageWrapper__section">
        <Container>
          <MainHeader tabindex={0}>LEKARZE PODSTAWOWEJ OPIEKI ZDROWOTNEJ</MainHeader>
          <CollapsibleBlocksWrapper>
            <CollapsibleBlock
              title="LEKARZ INTERNISTA"
              isSplited={false}
            >
              {internistsDoctors.map((doctor,index)=><DoctorRow key={index} name={doctor.name} specializations={doctor.specializations}/>)}
              <FullImgBlock img={OgolnaImg} alt="Harmonogram pracy lekarzy na oddziale ogólnym"/>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="LEKARZ PEDIATRA"
              isSplited={false}
            >
              {pediatrician.map((doctor,index)=><DoctorRow key={index} name={doctor.name} specializations={doctor.specializations}/>)} 
              <FullImgBlock img={DzieciecaImg} alt="Harmonogram pracy lekarzy na oddziale pediatrii"/>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="STRADOMIA WIERZCHNIA"
              isSplited={false}
            >
              {internistsDoctors.map((doctor,index)=><DoctorRow key={index} name={doctor.name} specializations={doctor.specializations}/>)}
              <FullImgBlock img={StradomiaImg} alt="Harmonogram pracy lekarzy s Stradomi Wierzchniej"/>
            </CollapsibleBlock>
          </CollapsibleBlocksWrapper>
        </Container>
      </section>
    </PageWrapper>
  )
}

export default BasicHealthcarePage;